import { combineReducers } from 'redux'

// Import application reducers
import base from './base'

const rootReducer = combineReducers({
  base
})

export default rootReducer
