import React from 'react'
import { Segment, Dimmer, Loader } from 'semantic-ui-react'

// Styling
import './Loading.css'

function Loading (props) {
  return (
    <div className="loading">
      <Segment className="loading-segment">
        <Dimmer active page>
          <Loader size="massive">Loading...</Loader>
        </Dimmer>
      </Segment>
    </div>
  )
}

export default Loading
