const PREFIX = 'trading:'

export const JWT_TOKEN_KEY = 'jwtToken'

export const getLocalStoreValue = (key) => {
  const newKey = PREFIX + key
  let parsedResult
  const result = localStorage.getItem(newKey)
  try {
    parsedResult = JSON.parse(result)
  } catch {
    console.log('LocalStorage Error: Failed to decode token.')
  }
  return parsedResult
}

export const setLocalStoreValue = (key, value) => {
  const newKey = PREFIX + key

  localStorage.setItem(newKey, JSON.stringify(value))
  return true
}

export const removeLocalStoreValues = () => {
  localStorage.removeItem(`${PREFIX}${JWT_TOKEN_KEY}`)
}
