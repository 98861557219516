import { createStore, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { composeWithDevTools } from '@redux-devtools/extension'

// Import redux sagas and reducers
import rootReducer from './reducers/index'
import rootSaga from './sagas/index'

// Add redux devtools functionality to redux store
const composeEnhancers = composeWithDevTools({})

// Setup saga middleware
const sagaMiddleware = createSagaMiddleware()

// Attach reducers and extended functionality to store
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
)

// Run sagas
sagaMiddleware.run(rootSaga)

export default store
