import React, { Component } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { Menu, Dropdown } from 'semantic-ui-react'

// Styling
import './Header.css'

class Header extends Component {
  render () {
    return (
      <div className='header'>
      </div>
    )
  }
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

const withLocation = Component => props => {
  const location = useLocation()

  return <Component {...props} location={location} />
}

export default withLocation(connect(mapStateToProps, mapDispatchToProps)(Header))
