import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import ReactDOM from "react-dom/client";

// React application
import App from './App'

// Redux Store
import store from './store'

// CSS
import './index.css'

// Create app root
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <BrowserRouter basename="/">
      <App/>
    </BrowserRouter>
  </Provider>
)

window.store = store
