import React, { Component } from 'react'
import { Message } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

class Error extends Component {
  render () {
    return (
      <Message negative>
        <Message.Header>{ this.props.errorHeader || 'Error' }</Message.Header>
        <p>{ this.props.error }</p>
      </Message>
    )
  }
}

Error.propTypes = {
  error: PropTypes.string,
  errorHeader: PropTypes.string
}

const mapStateToProps = state => {
  return {
    error: state.base.error,
    errorHeader: state.base.errorHeader
  }
}

export default connect(mapStateToProps)(Error)
