import axios from 'axios'

export function apiRequest (config, token, csrf = false) {
  const callConfig = { ...config }

  // If no method specific, default to GET
  if (!config.method) {
    callConfig.method = 'GET'
  }

  // Set additional headers
  callConfig.headers = {
    ...callConfig.headers,
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  }

  // Inject token if provided
  if (token) {
    callConfig.headers = { ...callConfig.headers, Authorization: `${token}` }
  }

  // Set call defaults
  callConfig.responseType = 'json'

  return axios(callConfig)
}

export function getStatusFromError (httpError) {
  const { error } = httpError
  if (error && error.response && error.response.status) {
    return error.response.status
  }
}

export function URLEncode (param) {
  let output = ''
  let x = 0
  const clearString = param.toString()
  const regex = /(^[a-zA-Z0-9_.]*)/
  while (x < clearString.length) {
    const match = regex.exec(clearString.substr(x))
    if (match != null && match.length > 1 && match[1] !== '') {
      output += match[1]
      x += match[1].length
    } else {
      if (clearString[x] === ' ') {
        output += '+'
      } else {
        const charCode = clearString.charCodeAt(x)
        const hexVal = charCode.toString(16)
        output += `%${hexVal.length < 2 ? '0' : ''}${hexVal.toUpperCase()}`
      }
      x += 1
    }
  }
  return output
}
