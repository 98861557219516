import { fork, all } from 'redux-saga/effects'

import base from './base'

function * rootSaga () {
  yield all([
    fork(base)
  ])
}

export default rootSaga
