import React, { Component } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Actions
import actions from './actions'

// Import semantic-ui-container
import { Container, Modal } from 'semantic-ui-react'
import 'semantic-ui-less/semantic.less'

// Application Components
import Header from './components/common/Header'
import Loading from './components/common/Loading'
import Error from './components/common/Error'

// Service Components
import LandingPage from './components/landingPage/LandingPage'

// Main React application
class App extends Component {
  constructor (props) {
    super(props)
  }

  generateErrorPopup () {
    const { popupError, popupErrorHeader } = this.props

    return (
      <Modal
        className='error-popup'
        closeIcon
        onClose={ () => this.props.unsetPopupError() }
        open={ !!this.props.popupError }>
        <Header icon='archive' content='Archive Old Messages' />
        <Modal.Content>
          <h1>{ popupErrorHeader }</h1>
          <p>{ popupError }</p>
        </Modal.Content>
        <Modal.Actions/>
      </Modal>
    )
  }

  render () {
    return (
      <div className="app-body">
        <Header/>
        { this.generateErrorPopup() }
        <Container fluid={true}>
          { this.props.loading && <Loading/> }
          { this.props.error && <Error/> }
          <Routes>
            <Route path='/' Component={LandingPage}></Route>
          </Routes>
        </Container>
      </div>
    )
  }
}

// Setup default App property types
App.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.string,
  errorHeader: PropTypes.string,
  popupError: PropTypes.string,
  popupErrorHeader: PropTypes.string
}

// Connect state to application props
const mapStateToProps = state => ({
  loading: state.base.loading,
  error: state.base.error,
  errorHeader: state.base.errorHeader,
  popupError: state.base.popupError,
  popupErrorHeader: state.base.popupErrorHeader
})

const mapDispatchToProps = dispatch => ({
  unsetPopupError: (data) => { dispatch(actions.unsetPopupError()) }
})

const withLocation = Component => props => {
  const location = useLocation();

  return <Component {...props} location={location} />;
}

// Connect hot loader to application
export default connect(mapStateToProps, mapDispatchToProps)(withLocation(App))
