import {
  SET_LOADING,
  UNSET_LOADING,
  SET_ERROR,
  UNSET_ERROR,
  SET_DATE_RANGE,
  SET_POPUP_ERROR,
  UNSET_POPUP_ERROR
} from '../actions/actionTypes'

const initialState = {
  loading: false,
  error: null,
  errorHeader: null,
  startDate: null,
  endDate: null,
  popupError: null,
  popupErrorHeader: null
}

const base = (state = initialState, action) => {
  const newState = { ...state }

  switch (action.type) {
    case SET_LOADING: {
      newState.loading = true
      return newState
    }
    case UNSET_LOADING: {
      newState.loading = false
      return newState
    }
    case SET_ERROR: {
      newState.error = action.data.error
      newState.errorHeader = action.data.errorHeader
      return newState
    }
    case UNSET_ERROR: {
      newState.error = null
      newState.errorHeader = null
      return newState
    }
    case SET_DATE_RANGE: {
      const { startDate, endDate } = action.data
      newState.startDate = startDate
      newState.endDate = endDate
      return newState
    }
    case SET_POPUP_ERROR: {
      newState.popupError = action.data.error
      newState.popupErrorHeader = action.data.errorHeader
      return newState
    }
    case UNSET_POPUP_ERROR: {
      newState.popupError = null
      newState.popupErrorHeader = null
      return newState
    }
    default: {
      newState.error = null
      newState.errorHeader = null
      return newState
    }
  }
}

export default base
