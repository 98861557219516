// Component actions
import {
  SET_LOADING,
  UNSET_LOADING,
  SET_ERROR,
  UNSET_ERROR,
  API_CALL,
  SET_DATE_RANGE,
  SET_POPUP_ERROR,
  UNSET_POPUP_ERROR
} from './actionTypes'

export const setLoading = data => {
  return { type: SET_LOADING, data }
}

export const unsetLoading = data => {
  return { type: UNSET_LOADING, data }
}

export const setError = data => {
  return { type: SET_ERROR, data }
}

export const unsetError = data => {
  return { type: UNSET_ERROR, data }
}

export const apiCall = data => {
  return { type: API_CALL, data }
}

export const setDateRange = data => {
  return { type: SET_DATE_RANGE, data }
}

export const setPopupError = data => {
  return { type: SET_POPUP_ERROR, data }
}

export const unsetPopupError = data => {
  return { type: UNSET_POPUP_ERROR, data }
}
