import { all, call, put, takeEvery } from 'redux-saga/effects'
import { JWT_TOKEN_KEY, getLocalStoreValue } from '../utils/localStorage'
import { apiRequest } from '../utils/apiCaller'
import { API_CALL } from '../actions/actionTypes'
import actions from '../actions'

export default function* apiSagas () {
  yield all([apiListener()])
}

function* apiListener () {
  yield takeEvery(API_CALL, apiCaller)
}

function* apiCaller (action) {
  const csrf = action.csrf || false
  const { history } = action
  const token = yield call(getLocalStoreValue, JWT_TOKEN_KEY)
  const startSpinner = action.spinner || false

  try {
    if (startSpinner) {
      yield put(actions.setLoading())
    }
    const response = yield call(apiRequest, action.config, token, csrf)
    if (response) {
      yield put({ type: action.successAction, data: response.data, history })
    }
  } catch (error) {
    const { response } = error
    const { status } = response


    // Buid and attach error message
    if (response && response.data && response.data.error) {
      error.message = response.data.error
    }

    yield put({ type: action.failureAction, error })
  } finally {
    if (startSpinner) {
      yield put(actions.unsetLoading())
    }
  }
}
